//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ToggleSlider',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
      default: 48,
    },
    height: {
      type: Number,
      required: false,
      default: 24,
    },
  },
  data () {
    return {
      wrapperPadding: 4,
    }
  },
  computed: {
    heightNormalized () {
      return Math.floor(this.height)
    },
    widthNormalized () {
      return Math.floor(this.width)
    },
    thumbSize () {
      return Math.ceil(this.height * 2 / 3)
    },
    labelStyle () {
      return {
        width: `${this.widthNormalized}px`,
        height: `${this.heightNormalized}px`,
      }
    },
    wrapperStyle () {
      return {
        marginLeft: `${this.wrapperPadding}px`,
        width: `${this.width - this.thumbSize - this.wrapperPadding * 2}px`,
      }
    },
    thumbStyle () {
      return {
        width: `${this.thumbSize}px`,
        height: `${this.thumbSize}px`,
      }
    },
  },
}
